import React from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl, {
  GeoJSONSource,
  LngLat,
  MapboxEvent,
  MapLayerMouseEvent,
} from 'mapbox-gl';
import {
  Map as ReactMap,
  ScaleControl,
  MapRef,
  ViewStateChangeEvent,
  Source,
  Layer,
  GeolocateControl,
  NavigationControl,
  FullscreenControl,
  Marker,
  AttributionControl,
} from 'react-map-gl';

import { useNavigate, useMatch, NavigateFunction } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import * as GeoJSON from 'geojson';
import { debounce } from 'lodash';

import SportsLocationOverlayLayer from '../SvgOverlay/SportsLocationOverlayLayer';

import GroupToggle from '../GroupToggle/GroupToggle';
import MapRecenter from '../MapRecenter/MapRecenter';
import ApiConfig from '../../services/apiConfiguration';
import { SportsLocation } from '../../services/SportsLocation/SportsLocation.service';
import { LatLngZoom, parseLatLngZoomParam } from '../../utils/utils';
import pkg from '../../../package.json';
import 'mapbox-gl/dist/mapbox-gl.css';
import SmartracksLocationInfo from '../SmartracksLocationInfo/SmartracksLocationInfo';

import {
  TRACKS_LAYER_ID,
  tracksClusterLayer,
  tracksUnclusteredPointsLayer,
} from './layers';

// Importing mapboxgl and setting the worker class is a workaround for this issue: https://github.com/visgl/react-map-gl/issues/1266#issuecomment-753686953
// (mapboxgl as any).workerClass = () =>
//   new Worker(new URL('mapbox-gl/dist/mapbox-gl-csp-worker', import.meta.url));
(mapboxgl as any).workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MAPBOX_TOKEN = ApiConfig.MAPBOX_TOKEN || '';
// Careful: MIN_SVG_ZOOM_LEVEL needs to be an integer, because it affects clustering which in turn are only evaluated at integer zoom levels
const MIN_SVG_ZOOM_LEVEL = 13;

type MapProps = {
  mapRef: React.RefObject<MapRef>;
  mapStyle?: string | null;
  sportsLocations?: SportsLocation[];
  handleViewLocation: (location: SportsLocation | undefined) => Promise<void>;
  onRegionSelected?: (regionFilter: string | number[] | undefined) => void;
};
export const defaultLatLngZoom: LatLngZoom = {
  latitude: 17,
  longitude: 4,
  zoom: 1.5,
};

const Map = (props: MapProps) => {
  const theme = useTheme();
  const {
    mapRef,
    sportsLocations,
    mapStyle,
    handleViewLocation,
    onRegionSelected,
  } = props;
  const navigate = useNavigate();
  const latLngZoomURLParam = parseLatLngZoomParam(
    useMatch('*')?.params['*']?.substring(1) ?? '',
    defaultLatLngZoom
  );
  const [groupPins = true, setGroupPins] = useQueryParam(
    'grouped',
    BooleanParam
  );

  const [smartracksLocationName = undefined, setSmartracksLocationName] =
    useQueryParam('facility', StringParam);

  const [latLngZoom, setLatLngZoom] = React.useState<LatLngZoom>(
    latLngZoomURLParam.zoom
      ? {
          latitude: latLngZoomURLParam.latitude,
          longitude: latLngZoomURLParam.longitude,
          zoom: latLngZoomURLParam.zoom,
        }
      : defaultLatLngZoom
  );

  const [hoveredFeatureId, setHoveredFeatureId] = React.useState<
    string | number | undefined
  >(undefined);

  /**
   * Changes in the position shown by the map should be represented in the current URL.
   * This needs to be debounced to not overwhelm the browser with too many updates.
   * Using React.useMemo ensures that the callback is persistent between renders
   * or otherwise debouncing would be useless.
   */
  const debouncedNavigate = React.useMemo(
    () =>
      debounce((url: string, nav: NavigateFunction) => {
        nav(url + window.location.search, { replace: true });
      }, 500),
    []
  );

  React.useEffect(() => {
    if (
      latLngZoom.latitude === defaultLatLngZoom.latitude &&
      latLngZoom.longitude === defaultLatLngZoom.longitude &&
      latLngZoom.zoom === defaultLatLngZoom.zoom
    )
      debouncedNavigate('/', navigate);
    else {
      const url = `/@${latLngZoom.latitude.toFixed(
        7
      )},${latLngZoom.longitude.toFixed(7)},${latLngZoom.zoom.toFixed(2)}z`;
      debouncedNavigate(url, navigate);
    }
  }, [latLngZoom, navigate, debouncedNavigate]);

  /**
   * Zooming in and out changes which locations are visible to the user
   * and therefore what images are overlaid. This needs to be updated
   * for every change of the zoom level or the lat/lng.
   */
  const visibleLocations = React.useMemo(() => {
    let locationsInBounds = [] as SportsLocation[];
    if (
      sportsLocations &&
      mapRef.current &&
      latLngZoom.zoom >= MIN_SVG_ZOOM_LEVEL
    ) {
      const map = mapRef.current.getMap();
      const bounds = map.getBounds();

      // Only checking if the current viewport (aka map bounds) contain the anchor location
      // is not sufficient, because zooming in on a certain part of the track
      // may result in the anchor location being outside of the viewport and thus
      // the sportslocation being hidden.
      // The track should be shown if the viewport either contains the anchor location
      // or if the viewport center is less than a threshold away from the anchor location.
      locationsInBounds = sportsLocations.filter((l) => {
        const anchorLngLat = new LngLat(
          l.anchorLocation.longitude,
          l.anchorLocation.latitude
        );
        return (
          bounds.contains(anchorLngLat) ||
          bounds.getCenter().distanceTo(anchorLngLat) < 500
        );
      });
    }
    return locationsInBounds;
  }, [sportsLocations, mapRef, latLngZoom]);

  /**
   * Since the short name of a location is used to represent it being
   * selected (aka popup opened) in the URL, we need to crawl
   * the list of all sports locations when the URL changes.
   * With a growing number of tracks this is an expensive operation,
   * hence the usage of React.useMemo
   */
  const LocationInfoData = React.useMemo(() => {
    if (sportsLocations && smartracksLocationName) {
      return sportsLocations.find((location) => {
        return (
          location.names[0]?.shortName ===
          decodeURIComponent(smartracksLocationName ?? '')
        );
      });
    }
    return undefined;
  }, [smartracksLocationName, sportsLocations]);

  const handleViewportChange = (event: ViewStateChangeEvent) => {
    setLatLngZoom({
      latitude: event.viewState.latitude,
      longitude: event.viewState.longitude,
      zoom: event.viewState.zoom,
    });
  };

  const handleClick = (event: MapLayerMouseEvent) => {
    const feature =
      event.features !== undefined ? event.features[0] : undefined;

    if (feature !== undefined) {
      const clusterId = feature.properties?.cluster_id ?? '';
      const featureSource = feature.source;

      if (clusterId !== '' && featureSource) {
        const source = mapRef.current?.getSource(
          featureSource
        ) as GeoJSONSource;

        if (source !== null) {
          source.getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            const point = feature.geometry as GeoJSON.Point;
            if (point)
              mapRef.current?.flyTo({
                center: [point.coordinates[0], point.coordinates[1]],
                zoom: zoom + 1,
                duration: 500,
              });
          });
        }
      } else {
        if (feature !== undefined && !feature.properties?.cluster_id) {
          const locationName =
            JSON.parse(feature.properties?.names)[0]?.shortName ?? '';
          if (locationName !== '')
            setSmartracksLocationName(encodeURIComponent(locationName));
          handleViewLocation(feature.properties as SportsLocation);
        }
      }
    }
  };

  const handleMapLoad = (ev: MapboxEvent) => {
    mapRef.current?.loadImage(
      `${process.env.PUBLIC_URL}/img/smartracks-pin.png`,
      (err, img) => {
        if (err || img === undefined) return;
        if (!mapRef.current?.hasImage('smartracks-marker'))
          mapRef.current?.addImage('smartracks-marker', img);
      }
    );

    mapRef.current?.loadImage(
      `${process.env.PUBLIC_URL}/img/non-smartracks-pin.png`,
      (err, img) => {
        if (err || img === undefined) return;
        if (!mapRef.current?.hasImage('non-smartracks-marker'))
          mapRef.current?.addImage('non-smartracks-marker', img);
      }
    );
    mapRef.current?.loadImage(
      `${process.env.PUBLIC_URL}/img/smartracks-cluster-symbol.png`,
      (err, img) => {
        if (err || img === undefined) return;
        if (!mapRef.current?.hasImage('smartracks-cluster-symbol'))
          mapRef.current?.addImage('smartracks-cluster-symbol', img);
      }
    );
    mapRef.current?.loadImage(
      `${process.env.PUBLIC_URL}/img/non-smartracks-cluster-symbol.png`,
      (err, img) => {
        if (err || img === undefined) return;
        if (!mapRef.current?.hasImage('non-smartracks-cluster-symbol'))
          mapRef.current?.addImage('non-smartracks-cluster-symbol', img);
      }
    );
    mapRef.current?.loadImage(
      `${process.env.PUBLIC_URL}/img/mixed-cluster-symbol.png`,
      (err, img) => {
        if (err || img === undefined) return;
        if (!mapRef.current?.hasImage('mixed-cluster-symbol'))
          mapRef.current?.addImage('mixed-cluster-symbol', img);
      }
    );
    /**
     * this is necessary since some web-browsers of some devices has UI covers
     * part of screen, so the map will be cropped and some elemnts will be covered under the UI
     * in this way we alway calculating per convention that the screen UI will always take 1% of vh and resizing our Map to this
     */
  };

  const smarTracksLocationsGeoJson = React.useMemo(() => {
    // GeoJSON is not typescript-friendly, thus we ignore the type here
    // in order to be able to access the parse function
    return (GeoJSON as any).parse(sportsLocations, {
      Point: ['anchorLocation.latitude', 'anchorLocation.longitude'],
    }) as GeoJSON.FeatureCollection;
  }, [sportsLocations]);

  const handleRecenter = () => {
    mapRef.current?.flyTo({
      center: [defaultLatLngZoom.longitude, defaultLatLngZoom.latitude],
      zoom: defaultLatLngZoom.zoom,
      duration: 1000,
    });
  };

  /**
   * Implements a hover effect when the mouse is moved over cluster features
   */
  const handleMouseMove = (e: mapboxgl.MapLayerMouseEvent) => {
    const map = e.target;
    if (hoveredFeatureId)
      map.setFeatureState(
        { id: hoveredFeatureId, source: TRACKS_LAYER_ID },
        { hover: false }
      );

    if (
      e.features &&
      e.features.length > 0 &&
      e.features[0].layer.id === tracksClusterLayer.id
    ) {
      map.setFeatureState(e.features[0], { hover: true });
      setHoveredFeatureId(e.features[0].id);
    } else setHoveredFeatureId(undefined);
  };

  const handleMouseLeave = (e: mapboxgl.MapLayerMouseEvent) => {
    const map = e.target;
    if (hoveredFeatureId) {
      map.setFeatureState(
        { id: hoveredFeatureId, source: TRACKS_LAYER_ID },
        { hover: false }
      );
      setHoveredFeatureId(undefined);
    }
  };
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mapCustomControls = () => (
    <>
      <ScaleControl unit="metric" />
      <NavigationControl showCompass={false} position="bottom-right" />
      <GeolocateControl
        positionOptions={{ enableHighAccuracy: true }}
        trackUserLocation={true}
        position="bottom-right"
      />
    </>
  );

  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;

  return (
    <ReactMap
      mapboxAccessToken={MAPBOX_TOKEN}
      ref={mapRef}
      style={{
        width: `${windowWidth}px`,
        height: `${windowHeight}px`,
      }}
      attributionControl={false}
      customAttribution={`v${
        pkg.version
      } - ${new Date().getFullYear()} &copy; <a href="https://humotion.net" target="blank">Humotion</a> | <a href="https://humotion.net/en/impressum-2/" target="blank">Legal Notice</a> | <a href="https://humotion.net/en/privacy-policy/" target="blank">Data Privacy</a>`}
      mapStyle={`mapbox://styles/mapbox/${mapStyle}`}
      interactiveLayerIds={[
        tracksClusterLayer.id ?? '',
        tracksUnclusteredPointsLayer.id ?? '',
      ]}
      minZoom={0}
      latitude={latLngZoom.latitude}
      longitude={latLngZoom.longitude}
      zoom={latLngZoom.zoom}
      onLoad={handleMapLoad}
      onMove={handleViewportChange}
      onClick={handleClick}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onRender={() => mapRef.current?.resize()}
      onStyleData={handleMapLoad}
    >
      {/** Built-in controls */}

      {!mobileScreen && (
        /**
         * since the default Attribution positioned bottom right making it hard
         * to marign and padding other elements and cropping other elements if its
         * opened, this custom Element is necessary
         */
        <>
          <AttributionControl
            customAttribution={`v${
              pkg.version
            } - ${new Date().getFullYear()} &copy; <a href="https://humotion.net" target="blank">Humotion</a> | <a href="https://humotion.net/en/impressum-2/" target="blank">Legal Notice</a> | <a href="https://humotion.net/en/privacy-policy/" target="blank">Data Privacy</a>`}
          />
          <FullscreenControl position="bottom-right" />
          {mapCustomControls()}
        </>
      )}

      {/** Box for custom controls */}
      <Box
        zIndex={7}
        sx={{
          position: 'absolute',
          right: 0,
          bottom: [document.fullscreenEnabled ? 155 : 114, 0],
        }}
      >
        <GroupToggle
          group={groupPins ?? true}
          onClick={(isGrouped) => setGroupPins(isGrouped ? undefined : false)}
        />
        <MapRecenter label="Recenter" onClick={handleRecenter} />
      </Box>

      {mobileScreen && (
        <>
          <Box zIndex={11}>
            <AttributionControl
              position="bottom-left"
              style={{
                zIndex: 3,
                bottom: -30,
                left: -4,
              }}
              customAttribution={`v${
                pkg.version
              } - ${new Date().getFullYear()} &copy; <a href="https://humotion.net" target="blank">Humotion</a> | <a href="https://humotion.net/en/impressum-2/" target="blank">Legal Notice</a> | <a href="https://humotion.net/en/privacy-policy/" target="blank">Data Privacy</a>`}
            />
          </Box>
          <FullscreenControl position="bottom-right" />
          {mapCustomControls()}
        </>
      )}

      {mapRef.current && visibleLocations && (
        <SportsLocationOverlayLayer
          visibleAtZoom={MIN_SVG_ZOOM_LEVEL}
          currentMapZoom={latLngZoom.zoom}
          sportsLocations={
            visibleLocations ? visibleLocations : new Array<SportsLocation>()
          }
        />
      )}

      {mapRef.current && sportsLocations && groupPins && (
        <Source
          id={TRACKS_LAYER_ID}
          type="geojson"
          data={smarTracksLocationsGeoJson}
          cluster={true}
          clusterMaxZoom={MIN_SVG_ZOOM_LEVEL - 1}
          clusterRadius={64}
          clusterProperties={{
            smartracksEnabledCount: [
              '+',
              ['case', ['==', ['get', 'smarTracksEnabled'], true], 1, 0],
            ],
          }}
        >
          <Layer {...tracksClusterLayer} />
          <Layer {...tracksUnclusteredPointsLayer} />
        </Source>
      )}

      {mapRef.current && sportsLocations && !groupPins && (
        <Source
          id={TRACKS_LAYER_ID}
          type="geojson"
          data={smarTracksLocationsGeoJson}
          cluster={false}
        >
          <Layer {...tracksClusterLayer} />
          <Layer {...tracksUnclusteredPointsLayer} />
        </Source>
      )}

      {mapRef.current &&
        sportsLocations &&
        latLngZoom.zoom > MIN_SVG_ZOOM_LEVEL &&
        (smarTracksLocationsGeoJson as any).features
          .filter((f: GeoJSON.Feature) => {
            // Hide markers that are not in the current view bounds
            const bounds = mapRef.current?.getBounds();
            if (bounds === undefined) return false;
            const point = f.geometry as GeoJSON.Point;
            return (
              point.coordinates[1] >= bounds.getSouthWest().lat &&
              point.coordinates[1] <= bounds.getNorthWest().lat &&
              point.coordinates[0] >= bounds.getSouthWest().lng &&
              point.coordinates[0] <= bounds.getNorthEast().lng
            );
          })
          .map((e: GeoJSON.Feature) => {
            console.log(e);
            return e;
          })
          .map((e: GeoJSON.Feature) => (
            <Box
              key={(e.properties as SportsLocation).sportsLocationGUID}
              sx={{ zIndex: '5 !important' }}
            >
              <Marker
                longitude={(e.geometry as GeoJSON.Point).coordinates[0]}
                latitude={(e.geometry as GeoJSON.Point).coordinates[1]}
                onClick={() => handleClick}
                style={{
                  zIndex: 5,
                  width: '23.25px /* 75% of the original png */',
                  height: '34.5px /* 75% of the original png */',
                }}
                anchor="bottom"
              >
                <img
                  alt="smartracks-pin"
                  style={{ width: '100%', height: '100%' }}
                  src={
                    e.properties && e.properties['smarTracksEnabled']
                      ? `${process.env.PUBLIC_URL}/img/smartracks-pin.png`
                      : `${process.env.PUBLIC_URL}/img/non-smartracks-pin.png`
                  }
                />
              </Marker>
            </Box>
          ))}

      {LocationInfoData && smartracksLocationName && (
        <Box>
          <SmartracksLocationInfo
            sportsLocation={LocationInfoData}
            onRegionSelected={onRegionSelected}
          />
        </Box>
      )}
    </ReactMap>
  );
};

export default Map;
