import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  FormControl,
  FormGroup,
  Divider,
  useMediaQuery,
  Box,
  Stack,
  Button,
  Drawer,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  BooleanParam,
  DelimitedArrayParam,
  StringParam,
} from 'serialize-query-params';
import { NumberParam, useQueryParam, useQueryParams } from 'use-query-params';

import ToggleFilter from './ToggleFilter';
import RadioFilter from './RadioFilter';
import FlagsFilter from './FlagsFilter';
import { SportsLocation } from '../../services/SportsLocation/SportsLocation.service';
import {
  QUERY_PARAM_SMARTRACKS_ENABLED,
  filterSmarTracksEnabled,
  QUERY_PARAM_INDOOR_OUTDOOR,
  filterIndoorOutdoor,
  QUERY_PARAM_AREA_TYPE,
  filterFunctionalAreas,
  FILTERABLE_FUNCTIONAL_AREAS,
  QUERY_PARAM_SURFACE_TYPE,
  FILTERABLE_SURFACE_TYPES,
  filterSurfaceType,
  QUERY_PARAM_AGILITY,
  filterAgilityTests,
  FILTERABLE_AGILITY_AREAS,
  QUERY_PARAM_FACILITY,
  QUERY_PARAM_SEARCH,
} from '../../services/Filters/Filters.service';

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CloseIcon from '@mui/icons-material/Close';
import { MapRef } from 'react-map-gl';
import { defaultLatLngZoom } from '../Map/Map';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FloatingCounter from '../FloatingCounterCard/FloatingCounterCard';

export type LocatiosFilterProps = {
  sportsLocations?: SportsLocation[];
  filteredSportsLocations?: SportsLocation[];
  counts?: number[];
  openMobileMenu: boolean;
  closeAfterApply: (applied: boolean) => void;
  mapRef: React.RefObject<MapRef>;
  onRegionSelected?: (regionFilter: string | number[] | undefined) => void;
  drawerStateCallBack: (drawerState: boolean) => void;
};
export const drawerWidth = 342;

const LocationsFilter = (props: LocatiosFilterProps) => {
  const {
    sportsLocations,
    filteredSportsLocations,
    counts,
    openMobileMenu,
    mapRef,
    onRegionSelected,
  } = {
    ...props,
  };
  const [mobileOpenState, setMobileOpenState] = React.useState(
    props.openMobileMenu
  );

  React.useEffect(() => {
    setMobileOpenState(props.openMobileMenu);
  }, [props.openMobileMenu]);

  const { t } = useTranslation();
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const totalTracks = sportsLocations?.length ?? -1;

  const [, setIfFilterApplied] = useQueryParam('filtered', NumberParam);

  const [filters, setFilters] = useQueryParams({
    [QUERY_PARAM_SMARTRACKS_ENABLED]: BooleanParam,
    [QUERY_PARAM_INDOOR_OUTDOOR]: StringParam,
    [QUERY_PARAM_AREA_TYPE]: DelimitedArrayParam,
    [QUERY_PARAM_SURFACE_TYPE]: DelimitedArrayParam,
    [QUERY_PARAM_AGILITY]: DelimitedArrayParam,
    [QUERY_PARAM_SEARCH]: StringParam,
    [QUERY_PARAM_FACILITY]: StringParam,
  });

  const numberOfSmartracks = filteredSportsLocations
    ? filteredSportsLocations.filter((e) => e.smarTracksEnabled === true).length
    : -1;

  const [drawerState, setDrawerState] = React.useState(false);

  const handleResetBtn = () => {
    setFilterCounter(0);
    setIfFilterApplied(undefined);
    let currentFilters = filters;
    let k: keyof typeof currentFilters;
    for (k in currentFilters) currentFilters[k] = undefined;
    setFilters(currentFilters);
    if (onRegionSelected) onRegionSelected(undefined);
  };

  const [filterCounter, setFilterCounter] = React.useState(0);

  const handleApplyFilter = () => {
    props.drawerStateCallBack(false);
    setFilterCounter(filterCounter + 1);
    setIfFilterApplied(filterCounter);
    setDrawerState(false);
    if (filters['search'] === undefined)
      mapRef.current?.flyTo({
        center: [defaultLatLngZoom.longitude, defaultLatLngZoom.latitude],
        zoom: defaultLatLngZoom.zoom,
        duration: 2000,
      });
  };

  const toggleDrawer = (open: boolean) => {
    setDrawerState(open);
    props.drawerStateCallBack(open);
  };

  return (
    <Stack
      direction="column"
      sx={{ height: '100% !important', width: '100% important' }}
    >
      {mobileScreen ? null : !filters['facility'] ? (
        <Box
          onClick={() => toggleDrawer(!drawerState)}
          sx={{
            zIndex: theme.zIndex.drawer + 1,
            display: 'flex',
            justifyContent: 'center',
            width: theme.spacing(8),
            height: theme.spacing(10),
            p: 1,
            ':hover': { backgroundColor: '#cccccc' },
            backgroundColor: '#fff',
            top: '50vh',
            position: 'absolute',
            transition: (theme) =>
              `left ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.easeOut}`,
            left: 0,
            ...(drawerState && {
              left: `${drawerWidth}px`,
              transition: (theme) =>
                `left ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp}`,
            }),
            borderTopRightRadius: '10px 10px',
            borderBottomRightRadius: '10px 10px',
            border: '0.5px solid #DCDCDC',
            cursor: 'pointer',
          }}
        >
          {drawerState ? (
            <KeyboardArrowLeftIcon
              sx={{
                color: '#000000',
                width: '25px',
                height: '35px',
              }}
            />
          ) : (
            <FilterAltOutlinedIcon
              sx={{
                color: '#000000',
                width: '25px',
                height: '35px',
              }}
            />
          )}

          {filters[QUERY_PARAM_AREA_TYPE] !== undefined ||
          filters[QUERY_PARAM_INDOOR_OUTDOOR] !== undefined ||
          filters[QUERY_PARAM_SURFACE_TYPE] !== undefined ||
          filters[QUERY_PARAM_AGILITY] !== undefined ? (
            <Box
              sx={{
                borderRadius: '100px !important',
                margin: '-1px !important',
                padding: '-1px !important',
              }}
              border={2}
              borderColor="red"
              height={3}
              width={3}
              bgcolor="red"
              color="red"
              fontSize={34}
            />
          ) : null}
        </Box>
      ) : null}
      <Drawer
        PaperProps={{ style: { height: '100vh' } }}
        sx={{
          zIndex: mobileScreen ? theme.zIndex.drawer + 1 : theme.zIndex.drawer,
          top: '0 !important',
        }}
        anchor={mobileScreen ? 'bottom' : 'left'}
        open={
          mobileScreen
            ? openMobileMenu
              ? mobileOpenState
              : openMobileMenu
            : drawerState
        }
        onClose={() => toggleDrawer(false)}
        ModalProps={{ keepMounted: true }}
        hideBackdrop={true}
        variant="persistent"
      >
        <Box
          id={'counter_element'}
          sx={{
            width: ['100%', drawerWidth],
            marginTop: mobileScreen ? '10px' : '100px',
            paddingX: mobileScreen ? theme.spacing(3) : theme.spacing(1.5),
            overflowY: 'auto',

            flexGrow: 1,
          }}
          role="presentation"
        >
          {mobileScreen ? (
            <>
              <Grid container spacing={2} direction="row">
                <Grid item xs={3}>
                  <CloseIcon
                    onClick={() => {
                      props.closeAfterApply(false);
                      setMobileOpenState(false);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Typography>{t('filterBy')}</Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Button
                    onClick={handleResetBtn}
                    variant="text"
                    sx={{
                      borderColor: '#A9A9A9',
                      color: '#8c8c8c',
                      fontWeight: 'bold',
                      borderRadius: '10px',
                      marginLeft: 5,
                      paddingBottom: 2,
                    }}
                  >
                    <RestartAltIcon />
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container direction={'row'}>
              <Grid container item xs={9} pt={1}>
                <FloatingCounter
                  totalLocationsNumber={totalTracks}
                  totalSmartracksLocationsNumber={numberOfSmartracks}
                  hGrade="h6"
                  fontWeight={400}
                />
                {/* <Grid item>
                  <Typography
                    variant="h6"
                    sx={{
                      color: '#000000',
                    }}
                  >
                    {totalTracks}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" textAlign="center">
                    |
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography variant="h6" color="primary">
                    {numberOfSmartracks}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Box sx={{ paddingTop: theme.spacing(1.5) }}>
                    <Tooltip disableFocusListener title={`${t('counterBtn')}`}>
                      <InfoOutlinedIcon
                        sx={{
                          fontSize: '15px',
                          paddingLeft: theme.spacing(0.5),
                          color: '#000000',
                        }}
                      />
                    </Tooltip>
                  </Box>
                </Grid> */}
              </Grid>
              <Grid container item xs={3} justifyContent={'flex-end'} pt={1}>
                <Grid item justifySelf={'flex-end'} ml={1} pl={1}>
                  <Button
                    onClick={handleResetBtn}
                    variant="text"
                    sx={{
                      borderColor: '#A9A9A9',
                      color: '#696969',
                      fontWeight: 'bold',
                      borderRadius: '10px',
                      justifyContent: 'right',
                    }}
                  >
                    <RestartAltIcon />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          {mobileScreen ? (
            <Divider
              variant="fullWidth"
              sx={{
                width: '100% !important',
                backgroundColor: '#A9A9A9',
                marginBottom: 0.2,
              }}
            />
          ) : null}
          <FormControl required component="fieldset" fullWidth>
            <FormGroup>
              <ToggleFilter
                element={'smartrackSwitch'}
                label={t('SmarTracksLocations') as string}
                defaultValue={true}
                queryParam={QUERY_PARAM_SMARTRACKS_ENABLED}
                queryParamType={BooleanParam}
                sportsLocations={filteredSportsLocations?.filter(
                  (s) => s.smarTracksEnabled === true
                )}
                evaluator={filterSmarTracksEnabled}
              />

              <Divider
                variant="fullWidth"
                sx={{
                  marginRight: theme.spacing(1.5),
                  backgroundColor: 'white',
                  marginY: theme.spacing(1),
                }}
              />
            </FormGroup>
          </FormControl>
          <Typography
            variant="overline"
            sx={{
              marginY: theme.spacing(1),
            }}
          >
            Type
          </Typography>
          <RadioFilter
            defaultValue={'*'}
            values={[t('All'), t('Indoors'), t('Outdoors')]}
            keys={['*', 'indoor', 'outdoor']}
            evaluator={filterIndoorOutdoor}
            queryParam={QUERY_PARAM_INDOOR_OUTDOOR}
            queryParamType={StringParam}
            counts={counts}
          />
          <Divider
            variant="fullWidth"
            sx={{
              marginRight: theme.spacing(1.5),
              backgroundColor: 'white',
              marginY: theme.spacing(0),
            }}
          />
          <Typography
            variant="overline"
            sx={{
              marginY: theme.spacing(1),
            }}
          >
            {t('SurfaceType')}
          </Typography>
          <FormControl
            required
            component="fieldset"
            fullWidth
            sx={{ marginY: theme.spacing(0) }}
          >
            <FormGroup>
              <FlagsFilter
                flagNames={FILTERABLE_SURFACE_TYPES}
                labels={[t('turf') as string, t('synthetic') as string]}
                defaultValues={[false, false]}
                queryParam={QUERY_PARAM_SURFACE_TYPE}
                queryParamType={DelimitedArrayParam}
                evaluator={filterSurfaceType}
                sportsLocations={filteredSportsLocations}
              />
            </FormGroup>
          </FormControl>
          <Divider
            variant="fullWidth"
            sx={{
              marginRight: theme.spacing(1.5),
              backgroundColor: 'white',
              marginY: theme.spacing(0),
            }}
          />
          <Typography
            variant="overline"
            sx={{
              marginY: theme.spacing(1),
            }}
          >
            {t('FunctionalArea')}
          </Typography>

          <FormControl
            required
            component="fieldset"
            fullWidth
            sx={{ marginY: theme.spacing(-0.5) }}
          >
            <FormGroup>
              <FlagsFilter
                flagNames={[
                  ...FILTERABLE_FUNCTIONAL_AREAS,
                  {
                    flagNames: FILTERABLE_AGILITY_AREAS,
                    labels: [
                      t('IllinoisAgilityTest') as string,
                      t('ArrowheadAgilityTest') as string,
                      t('5105Shuttle') as string,
                      t('ThreeConeDrill') as string,
                      t('CustomAgilityTest') as string,
                    ],
                    defaultValues: [false, false, false, false, false],
                    queryParam: QUERY_PARAM_AGILITY,
                    queryParamType: DelimitedArrayParam,
                    evaluator: filterAgilityTests,
                    sportsLocations: filteredSportsLocations,
                  },
                ]}
                labels={[
                  t('Laps') as string,
                  t('Sprint') as string,
                  t('LongJump') as string,
                  t('Triplejump') as string,
                  t('Polevault') as string,
                  t('Steeplechase') as string,
                  t('AgilityTests') as string,
                ]}
                defaultValues={[
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                ]}
                queryParam={QUERY_PARAM_AREA_TYPE}
                queryParamType={DelimitedArrayParam}
                evaluator={filterFunctionalAreas}
                sportsLocations={filteredSportsLocations}
              />
            </FormGroup>
          </FormControl>
        </Box>
        {mobileScreen ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              widht: '50%',
            }}
          >
            <Button
              onClick={() => {
                props.closeAfterApply(false);
                setMobileOpenState(false);
                handleApplyFilter();
              }}
              variant="outlined"
              sx={{
                borderColor: '#A9A9A9',
                color: 'white',
                fontWeight: 'bold',
                borderRadius: '5px',
                backgroundColor: theme.palette.primary.main,
                ' :hover': {
                  backgroundColor: theme.palette.primary.light,
                },
                width: '60% !important',
              }}
            >
              {`${t('show')} ${
                filters[QUERY_PARAM_SMARTRACKS_ENABLED]
                  ? numberOfSmartracks
                  : filteredSportsLocations?.length
              } ${t('locations')}`}
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              marginBottom: theme.spacing(1),
            }}
          >
            <Button
              onClick={handleApplyFilter}
              variant="outlined"
              sx={{
                borderColor: '#A9A9A9',
                color: '#696969',
                fontWeight: 'bold',
                borderRadius: 5,
                marginBottom: 0.7,
              }}
            >
              {`${t('applyFilter')}`}
            </Button>
          </Box>
        )}
      </Drawer>
    </Stack>
  );
};

export default LocationsFilter;
