import React from 'react';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  Radio,
  RadioProps,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Box,
  Container,
  Typography,
} from '@mui/material';

import {
  Layers,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from '@mui/icons-material';

import PopUpMenu from '../PopUpMenu/PopUpMenu';

export type ControlPanelProps = {
  currentStyle?: string | null;
  setMapStyle?: any;
};

export type LayerChangeArgs = {
  checked: boolean;
};

const MapStyleSelection = (props: ControlPanelProps) => {
  const [mapStyle = 'outdoors-v11', setMapStyle] = useQueryParam(
    'mapStyle',
    StringParam
  );
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMapStyle((event.target as HTMLInputElement).value);
  };

  function StyledRadio(props: RadioProps) {
    return (
      <Radio
        disableRipple
        color="primary"
        icon={<RadioButtonUnchecked />}
        checkedIcon={<RadioButtonChecked />}
        {...props}
      />
    );
  }

  return (
    <Box>
      <PopUpMenu menuIcon={<Layers titleAccess={t('MapStyle')} />}>
        <Container>
          <FormLabel>
            <Typography variant="subtitle1">{t('MapStyle')}</Typography>
          </FormLabel>
          <RadioGroup
            aria-label="position"
            name="position"
            value={mapStyle}
            onChange={handleChange}
          >
            <FormControlLabel
              value="outdoors-v11"
              control={<StyledRadio />}
              label={t('Outdoors') as string}
              labelPlacement="end"
            />
            <FormControlLabel
              value="satellite-streets-v11"
              control={<StyledRadio />}
              label={t('Satellite') as string}
              labelPlacement="end"
            />
            <FormControlLabel
              value="streets-v11"
              control={<StyledRadio />}
              label={t('Streets') as string}
              labelPlacement="end"
            />
            <FormControlLabel
              value="light-v10"
              control={<StyledRadio />}
              label={t('Light') as string}
              labelPlacement="end"
            />
            <FormControlLabel
              value="dark-v10"
              control={<StyledRadio />}
              label={t('Dark') as string}
              labelPlacement="end"
            />
          </RadioGroup>
        </Container>
      </PopUpMenu>
    </Box>
  );
};

export default MapStyleSelection;
